import React, { useState, useEffect , useCallback , useMemo } from "react"
import Layout from "../../components/services/layout"
import Seo from "../../components/seo"
import * as styles from "../../components/style/gift-program-individual.module.scss"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StaticImage } from "gatsby-plugin-image"
import axios from "axios";
import _ from "lodash"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../utils/font-awesome';
import ProductSelector from '../../components/services/product_selector';

const {GATSBY_BACKEND_URL} = process.env;

const GiftProgramIndividual = (props) => {
	const access_code = props.params.access_code;
	const [categories, setCategories] = useState([]);
	const [giftProgramData, setGiftProgramData ] = useState([]);
	const [loadingGiftProgram, setLoadingGiftProgram ] = useState(false);
	const [products, setProducts] = useState([]);
	const [showProducts, setShowProducts] = useState(false);

	const getCategoriesData = useCallback(async () => {
		await axios.get(`${GATSBY_BACKEND_URL}products/categories` , {})
				.then((res) => {
					setCategories(res.data);
				})
				.catch((error) => { 
					var result = {'request' : false};
					if (typeof error.response === 'object' && typeof error.response.data === 'object') {
						if(error.response.data.errors) {
							result.errors = _.flatten(_.toArray(error.response.data.errors));
						} else if (error.response.data.message) {
							result.errors = [error.response.data.message];
						}
					} else {
						result.errors = ['Something went wrong. Please try again.'];
					}
					setCategories(result);
				});
	},[])

	const giftProgramResult = useCallback(async () => {
		setLoadingGiftProgram(true);
		var requestData = {access_code : access_code}
		await axios.get(`${GATSBY_BACKEND_URL}customer/get-share-link` , { params: requestData })
				.then((res) => {
					setGiftProgramData(res.data);
				})
				.catch((error) => { 
					var result = {'request' : false};
					if (error.response && typeof error.response.data === 'object') {
						if(error.response.data.errors) {
							result.errors = _.flatten(_.toArray(error.response.data.errors));
						} else if (error.response.data.message) {
							result.errors = [error.response.data.message];
						}
					} else {
						result.errors = ['Something went wrong. Please try again.'];
					}
					setGiftProgramData(result);
				})
				.then(()=> {
					setLoadingGiftProgram(false);
				});
	},[access_code])

	const updateProductsSelected = (items) => {
		setProducts(items);
  	}

	const updateShowNext = (value) => {
		setShowProducts(value);
  	}
	const referalParameters = useMemo(() => {
		var url = "";
		if(giftProgramData.yotpo_data) {
			url = encodeURIComponent(`?sref_id=${giftProgramData.yotpo_data}&utm_campaign=referral_program&utm_source=loyalty&storefront=true`);
		}
		return url;
	}, [giftProgramData]);

	useEffect( () => {
		getCategoriesData()
	}, [getCategoriesData]) // execute in the first render

	useEffect( () => {
		giftProgramResult()
	}, [giftProgramResult]) // execute in the first render

	/*const shipMyGiftAction = (event) => {
		console.log(giftProgramData.yotpo_data);
		axios({
			method: "post",
			url: `https://www.healthycell.com/${referalParameters}`,
			data: {}
			})
			.then(response => {
				console.log('ok');
			})
			.catch(error => {
				console.log(error);
			})
			.then(() => {
			});

		//event.preventDefault();
	};*/

	return (
		<>
		<Layout>
			<Container fluid>
				<Row className={`${styles.titlePageIndividualHeader} bg-black text-center align-items-center`}>
					<Col xs="12" className="px-5">
						<h1 className="text-white">Welcome to Healthycell</h1>
					</Col>
				</Row>
			</Container>
			{ loadingGiftProgram &&
				<>
				<Row>
					<Col>
						<div className="text-center py-2">
							<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</Col>
				</Row>
				</>
			}
			{ !loadingGiftProgram && !giftProgramData.errors &&
				<Container>
					<Row className="pt-5">
						<Col>
							<div className="text-center mb-5">
								<h5><b>{giftProgramData.customer_name}</b> wants you to experience the Healthycell difference with a <b>FREE 7 day trial</b> pack.</h5>
								<h5>Choose your product below to get your ultra-absorbable gel supplements pack.</h5>
							</div>
							<div className="text-center">
								<h4><b>Why Healthycell?</b></h4>
								<h5>Healthycell delivers nutrients you can absorb, results you can feel and an experience you’ll love.</h5>
							</div>
						</Col>
					</Row>
					<div className="my-4">
						<Row className="align-items-center justify-content-center gap-4 gap-md-0">
							<Col xs="12" md="4" lg="3">
								<StaticImage
									src="../../images/gift_program_1.png"
									loading="lazy"
									height={280}
									formats={["auto", "webp", "avif"]}
									alt="Gift Program 1"
									placeholder="none"
									className="mx-auto d-block rounded-4"
								/>
							</Col>
							<Col xs="12" md="4" lg="3">
								<StaticImage
									src="../../images/gift_program_2.png"
									loading="lazy"
									height={280}
									formats={["auto", "webp", "avif"]}
									alt="Gift Program 2"
									placeholder="none"
									className="mx-auto d-block rounded-4"
								/>
							</Col>
							<Col xs="12" md="4" lg="3">
								<StaticImage
									src="../../images/gift_program_3.png"
									loading="lazy"
									height={280}
									formats={["auto", "webp", "avif"]}
									alt="Gift Program 3"
									placeholder="none"
									className="mx-auto d-block rounded-4"
								/>
							</Col>
						</Row>
					</div>
					<div className="separator d-flex justify-content-center align-items-center py-5">
						<div className="line"></div>
						<div className="arrow d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={'angle-down'} /></div>
					</div>

					<div className="text-center">
						<h2 className="mb-3">SELECT YOUR FREE GIFT*</h2>
						<p><b>*Limit one per household</b></p>
					</div>

					<div>
					<ProductSelector items={categories} onlyGiftItems={true} autoSelectProducts={giftProgramData.products} showImage={true} multiselect={false} updateProductsSelected={updateProductsSelected} updateContinueAction={updateShowNext} />
					</div>

					{ showProducts &&
						<>
							<div className="separator d-flex justify-content-center align-items-center py-5">
								<div className="line"></div>
								<div className="arrow d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={'angle-down'} /></div>
							</div>

							{products.length > 0 &&
								<>
									<div>
									{products.map((_product,p_i) => (
										<div key={`product_selected_${p_i}`} className={`mx-auto ${styles.itemToShip}`} >
											<Row className={`pb-3 align-items-center`}>
												<Col xs="12" md="4">
													{_product.gift_image && 
														<>
														<div>
															<img className={`img-fluid d-block mx-auto ${styles.imgProductShipGift}`} src={_product.gift_image} alt={_product.name}/>
														</div>
														</>
													}
												</Col>
												<Col xs="12" md="8">
													<div className={`p-3 ${styles.infoWrapper}`}>
														<div className="align-items-center d-flex justify-content-between flex-column flex-md-row">
															<div>
																<p className="mb-1">
																	<b>{_product.name}
																	{_product.gift_text !== "" &&
																		<>
																		<span> {_product.gift_text}</span>
																		</>
																	}
																	</b>
																</p>
																{_product.gift_description && 
																	<p className={`mb-0 ${styles.textDescription}`}>{_product.gift_description}</p>
																}
															</div>
															<div className="mt-2 mt-md-0 ms-0 ms-md-3">
																{ _product.add_to_cart_gift_by_shopify &&
																	<a target="_blank" style={{'--bs-btn-border-radius':'2px'}} rel="noreferrer" className={`btn btn-primary font-gotham-rounded-medium ${styles.btnShipGift}`} href={`${_product.add_to_cart_gift_by_shopify}${referalParameters}`}>Ship my Gift</a>
																}
															</div>
															
														</div>
													</div>

													<div className={`p-3 mt-3 ${styles.infoWrapper}`}>
														US Shipping Only
													</div>
												</Col>
											</Row>
										</div>
										))
									}
									</div>
								</>
							}
						</>
					}
				</Container>
			}
			{ !loadingGiftProgram && giftProgramData.errors &&
				<Container>
					<Row>
						<Col xs="12">
							<div className="alert alert-info my-4">Share link does not exist.</div>
						</Col>
					</Row>
				</Container>
			}
		</Layout>
		</>
	)
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Gifting Program" />

export default GiftProgramIndividual
